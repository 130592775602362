const $ = require("jquery");

import "slick-carousel";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Slick
$(document).ready(function () {
  $(".c-slider-news").slick({
    infinite: true,
    prevArrow:
      '<span class="slick-prev"><svg aria-hidden="true"><use xlink:href="/common/img/icon.svg#ico-arrow-left"></use></svg></span>',
    nextArrow:
      '<span class="slick-next"><svg aria-hidden="true"><use xlink:href="/common/img/icon.svg#ico-arrow-right"></use></svg></span>',
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: true,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
